import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { GeneralInputComponent } from '../../inputs/general-input/general-input.component';
import { GeneralInputDirective } from '../../../directives/inputs/general-input.directive';
import { GeneralButtonDirective } from '../../../directives/buttons/general-button.directive';
import { PasswordInputComponent } from '../../inputs/password-input/password-input.component';
import {
  clearToastAction,
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../../../core/store/actions/toast.actions';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { AuthService } from '../../../../core/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store';

@Component({
  selector: 'app-forgot-password-ui',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    ReactiveFormsModule,
    PasswordInputComponent,
    RouterModule,
    GeneralInputComponent,
    GeneralInputDirective,
    GeneralButtonDirective,
  ],
  templateUrl: './forgot-password-ui.component.html',
  styleUrl: './forgot-password-ui.component.css',
})
export class ForgotPasswordUiComponent {
  //Services
  authService = inject(AuthService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  store = inject(Store<AppState>);

  //Form control
  authForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  //API Call (Reset password)
  mutation = injectMutation((client) => ({
    mutationFn: (data: { email: string }) =>
      this.authService.resetPasswordRequest(data),
    onSuccess: ({ id, ...others }) => {
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'Check your inbox for a password reset email',
        })
      );
      this.authForm.reset();
    },
    onError: (error: any) => {
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while logging in. Try again shortly',
        })
      );
    },
  }));

  switchToRegister() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'register',
      },
    });
  }

  goBack() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null,
    });
  }

  switchToLogin() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'login',
      },
    });
  }

  onSubmit() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Resetting password. Please wait...',
      })
    );
    this.mutation.mutate(this.authForm.value as { email: string });
  }
}
