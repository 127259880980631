import {
  Component,
  computed,
  effect,
  inject,
  Input,
  signal,
  SimpleChanges,
  untracked,
  WritableSignal,
} from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NzTableModule } from 'ng-zorro-antd/table';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { ImageLoadDirective } from '../../../../shared/directives/image-load.directive';
import { ImageService } from '../../../../core/services/image.service';
import { loadingToastAction } from '../../../../core/store/actions/toast.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store';

interface ReceiptData {
  invoiceDate: Date;
  invoiceNo: string;
  billTo: {
    name: string;
    address: string;
    email: string;
  };
  billFrom: {
    name: string;
    address: string;
  };
  items: {
    description: string;
    quantity: number;
    rate: number;
    amount: number;
  }[];
  totalAmount: number;
}

@Component({
  selector: 'app-receipt',
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    NgxSkeletonLoaderModule,
    NzTableModule,
    ImageLoadDirective,
  ],
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css'],
})
export class ReceiptComponent {
  private units: string[] = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ];
  private teens: string[] = [
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];
  private tens: string[] = [
    'Ten',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];
  private thousands: string[] = [
    '',
    'Thousand',
    'Million',
    'Billion',
    'Trillion',
  ];

  store = inject(Store<AppState>);
  @Input() receiptData: ReceiptData;
  @Input() ref: string;
  @Input() isLoading: boolean;
  @Input({ required: true }) isInvoice: boolean;
  allImagesAreLoaded: WritableSignal<boolean> = signal(false);
  billsFetched: WritableSignal<boolean> = signal(false);

  redirectQueryparams = injectQueryParams('redirect');

  displayedColumns: string[] = [
    'Item',
    'Description',
    'Qty',
    'Rate (NGN)',
    'Amount (NGN)',
  ];

  get dataLoading() {
    return Array.from({ length: 5 }, (data) => {
      return data;
    });
  }

  imageLoadedService = inject(ImageService);
  get formatedData() {
    return {
      ...this.receiptData,
      invoiceDate: dayjs(this.receiptData.invoiceDate).format('MMMM DD, YYYY'),
      items: (this.receiptData?.items || []).map((item) => ({
        ...item,
        rate: new Intl.NumberFormat('en-NG', {
          style: 'currency',
          currency: 'NGN',
        }).format(item.rate),
        amount: new Intl.NumberFormat('en-NG', {
          style: 'currency',
          currency: 'NGN',
        }).format(item.amount),
      })),
      totalAmount: new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      }).format(this.receiptData.totalAmount),
      totalAmountInWords: this.convertToWords(this.receiptData.totalAmount),
    };
  }

  async downloadPDF() {
    const data = document.getElementById('receiptContent');
    if (data) {
      const docStyles = getComputedStyle(data as HTMLDivElement);
      const docHeight = parseInt(docStyles.height);
      const docWidth = parseInt(docStyles.width);
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: [docWidth, docHeight],
      });
      const canvas = await html2canvas(data, {
        scale: 4,
        backgroundColor: '#fff',
      });

      const canvasDataURL = canvas.toDataURL('image/jpeg', 1.0);

      const imgProperties = pdf.getImageProperties(canvasDataURL);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(canvasDataURL, 'JPEG', 4, 4, pdfWidth, pdfHeight);

      pdf.save(this.isInvoice ? 'Invoice' : 'Receipt');
    }
  }

  convertToWords(number: number): string {
    if (number === 0) return this.units[0];

    let words = '';
    let thousandsCount = 0;

    while (number > 0) {
      if (number % 1000 != 0) {
        words =
          this.convertHundreds(number % 1000) +
          this.thousands[thousandsCount] +
          ' ' +
          words;
      }
      number = Math.floor(number / 1000);
      thousandsCount++;
    }

    return words.trim();
  }

  private convertHundreds(number: number): string {
    let words = '';

    if (number > 99) {
      words += this.units[Math.floor(number / 100)] + ' Hundred ';
      number %= 100;
    }

    if (number > 10 && number < 20) {
      words += this.teens[number - 11] + ' ';
    } else if (number >= 20 || number === 10) {
      words += this.tens[Math.floor(number / 10) - 1] + ' ';
      number %= 10;
    }

    if (number > 0 && number < 10) {
      words += this.units[number] + ' ';
    }

    return words;
  }

  constructor() {
    effect(() => {
      const allImagesAreFetched = this.allImagesAreLoaded();
      const billFetched = this.billsFetched();

      untracked(async () => {
        if (!!allImagesAreFetched && !!billFetched) {
          await this.downloadPDF();
          const redirect = this.redirectQueryparams();
          window.location.href = redirect;
        }
      });
    });

    effect(() => {
      const redirect = this.redirectQueryparams();
      untracked(() => {
        if (!!redirect) {
          this.store.dispatch(
            loadingToastAction({
              autohide: false,
              delay: 7000,
              placement: 'top-end',
              message: 'Downloading receipt. Please wait...',
            })
          );
        }
      });
    });

    this.imageLoadedService.imagesLoading$.subscribe((data) => {
      const redirect = this.redirectQueryparams();

      if (data === 0 && redirect) {
        this.allImagesAreLoaded.set(true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    const redirect = this.redirectQueryparams();
    const billId = changes?.receiptData?.currentValue?.bill?.id;
    const isLoading = changes?.isLoading?.currentValue;
    if (!!redirect && !!billId && !isLoading) {
      this.billsFetched.set(true);
    }
  }
}

//https://paykaduna.com/payment_summary?ref=90782609&amount=250
