import { CommonModule } from '@angular/common';
import { Component, inject, Input, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { SvgIconComponent } from 'angular-svg-icon';
import { AppState } from '../../../core/store';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { setStateAction } from '../../../core/store/actions/invoice.actions';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthService } from '../../../core/services/auth.service';

const navList = [
  {
    name: 'Dashboard',
    link: '/user/dashboard',
    type: 'All',
    icon: 'assets/navMenu/dashboard-icon.svg',
  },
  {
    name: 'Tax Trails',
    link: '/user/payments',
    type: 'All',
    icon: 'assets/navMenu/tax-payment-icon.svg',
  },
  {
    name: 'Invoices',
    link: '/user/invoices',
    type: 'All',
    icon: 'assets/navMenu/invoice.svg',
  },
  {
    name: 'Payment History',
    link: '/user/payment-history',
    type: 'All',
    icon: 'assets/navMenu/payment-history-icon.svg',
  },
  {
    name: 'E-Services',
    link: '/user/e-services',
    type: 'All',
    icon: 'assets/navMenu/e-service.svg',
  },
];

const navList2 = [
  {
    name: 'Home',
    link: '/',
    type: 'All',
    exact: true,
    icon: 'assets/svgs/home.svg',
  },
  {
    name: 'Profile',
    link: '/user/profile',
    type: 'All',
    icon: 'assets/navMenu/paye-manager-icon.svg',
  },
  {
    name: 'Help & Support',
    link: '/user/help-support',
    type: 'All',
    icon: 'assets/navMenu/user-settings.svg',
  },
  {
    name: 'Log Out',
    link: '/',
    type: 'Danger',
    exact: true,
    icon: 'assets/svgs/menu/log-out.svg',
  },
];

@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    NzButtonModule,
    NzMenuModule,
    NgScrollbarModule,
    NzToolTipModule,
  ],
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.css',
  // host: {
  //   class: 'max-w-[272px] w-full shrink-0',
  // },
})
export class NavMenuComponent {
  store = inject(Store<AppState>);
  authService = inject(AuthService);
  router = inject(Router);

  @Input({ required: true }) isCollapsed = false;

  navList = signal(navList);
  navList2 = signal(navList2);

  showInvoice() {
    this.store.dispatch(setStateAction({ modalState: true }));
  }

  logoutHandler(name: string, path: string) {
    this.router.navigateByUrl(path);
    if (name === 'Log Out') {
      this.authService.clearUserData();
    }
  }
}
