<header
  [ngClass]="{
    'body-font py-6 px-8': true
  }"
>
  <div
    class="flex flex-col flex-wrap items-center justify-center mx-auto md:flex-row gap-[80px]"
  >
    <div class="flex flex-col gap-2 justify-center-center">
      <a
        class="flex items-center font-medium text-gray-900 title-font md:mb-0"
        [routerLink]="'/'"
      >
        <img
          [ngSrc]="'assets/svgs/logoLandingPage.svg'"
          class="w-[191px] rounded-full overflow-hidden object-fill"
          priority
          width="191"
          height="47"
        />
      </a>

      <a
        class="ml-3 text-white cursor-pointer hover:text-white"
        (click)="toggleSideMenuVisible()"
      >
        <svg-icon
          [svgStyle]="{ 'width.px': 30, }"
          src="assets/svgs/menu-icon.svg"
        ></svg-icon>
      </a>
    </div>

    <div
      class="flex justify-center flex-1"
      *ngIf="currentPath && currentPath[0] !== 'payment_summary'"
    >
      <nav
        class="flex flex-wrap items-center justify-center flex-1 gap-6 text-base"
      >
        @for (item of navLists; track $index) {
        <a
          class="text-sm text-white nav-item"
          [routerLink]="[item.link]"
          routerLinkActive="nav-item-active"
          [routerLinkActiveOptions]="{ exact: true }"
          >{{ item.name }}</a
        >
        }
      </nav>
    </div>

    <div>
      <div [ngClass]="{ flex: true, '!hidden': !authService.isLoggedIn }">
        <button
          class="flex px-4 py-2 mr-3 bg-white bg-opacity-25 rounded-md align-items-center"
          (click)="gotoDashboard()"
        >
          <p class="text-sm text-white">Dashboard</p>
        </button>

        <button
          class="flex px-4 py-2 mr-3 bg-white bg-opacity-25 rounded-md align-items-center"
          (click)="logoutHandler()"
        >
          <p class="text-sm text-white">Logout</p>
        </button>
      </div>
      <div [ngClass]="{ flex: true, '!hidden': authService.isLoggedIn }">
        <button
          class="flex px-4 py-2 mr-3 bg-white bg-opacity-25 rounded-md align-items-center"
          (click)="switchToLogin()"
        >
          <p class="text-sm text-white">Sign in</p>
        </button>

        <button
          class="flex px-4 py-2 mr-3 bg-white bg-opacity-25 rounded-md align-items-center"
          (click)="openSignUpPopup()"
        >
          <p class="text-sm text-white">Register</p>
        </button>
      </div>
    </div>
  </div>
  <app-modal (closeModalEvent)="clickOut($event)">
    <div [ngClass]="{ hidden: activeDisplay !== 'login' }">
      <app-login-ui></app-login-ui>
    </div>
    <div [ngClass]="{ hidden: activeDisplay !== 'register' }">
      <app-register-ui [accounTypeList]="accounTypeList()"></app-register-ui>
    </div>
    <div [ngClass]="{ hidden: activeDisplay !== 'forgot-password' }">
      <app-forgot-password-ui></app-forgot-password-ui>
    </div>
    <div [ngClass]="{ hidden: activeDisplay !== 'reset-message' }">
      <app-password-reset-email-notice-ui></app-password-reset-email-notice-ui>
    </div>
    <div [ngClass]="{ hidden: activeDisplay !== 'reset-success' }">
      <app-reset-successful-notice-ui></app-reset-successful-notice-ui>
    </div>
    <div [ngClass]="{ hidden: activeDisplay !== 'reset-password' }">
      <app-reset-password-ui></app-reset-password-ui>
    </div>
  </app-modal>
</header>
