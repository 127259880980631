import { Directive, ElementRef, HostListener } from '@angular/core';
import { ImageService } from '../../core/services/image.service';

@Directive({
  selector: '[appImg]',
  standalone: true,
})
export class ImageLoadDirective {
  constructor(private el: ElementRef, private imageService: ImageService) {
    imageService.imageLoading(el.nativeElement);
  }

  @HostListener('load')
  onLoad() {
    this.imageService.imageLoadedOrError(this.el.nativeElement);
  }

  @HostListener('error')
  onError() {
    this.imageService.imageLoadedOrError(this.el.nativeElement);
  }
}
