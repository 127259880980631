<div
  id="receiptContent"
  class="w-[827px] relative mx-auto shadow-[0px_0px_20px_0px_rgba(6,6,6,0.1)] bg-white"
>
  <img
    src="assets/images/icon-logo.png"
    class="w-[300px] overflow-hidden object-fill absolute left-0 right-0 top-0 bottom-0 m-auto z-[-1000] opacity-5"
    appImg
  />

  <img
    src="assets/images/receipt/header.png"
    class="object-fill overflow-hidden"
    appImg
  />

  <div class="w-full px-10">
    <div class="flex justify-end mt-[-120px]">
      <img
        src="assets/images/receipt/kadir_logo.webp"
        class="w-[160px] overflow-hidden object-fill"
        appImg
      />
    </div>

    <div class="flex justify-between py-4">
      <p class="text-2xl font-semibold uppercase">
        {{ !!isInvoice ? "Invoice" : "Receipt" }}
      </p>

      <div class="flex flex-col">
        <span class="flex space-x-1 items-center mt-[-10px]">
          <svg-icon
            src="assets/svgs/receipt/invoice-no-symbol.svg"
            [svgStyle]="{ 'width.px': 20 }"
          >
          </svg-icon>
          <p class="text-xl font-extrabold tracking-wide">{{ ref }}</p>
        </span>

        <p class="mt-[-10px]">Invoice Date: {{ formatedData?.invoiceDate }}</p>
      </div>
    </div>

    <div class="flex justify-between flex-nowrap gap-[70px]">
      <div [ngClass]="{ 'w-full flex-1': true }">
        <p class="text-xl font-bold">Bill To:</p>
        @if (isLoading) {
        <div>
          <ng-container *ngTemplateOutlet="skeleton"></ng-container>
        </div>
        }@else {
        <div class="w-full">
          <p class="mt-3 font-semibold capitalize text-md">
            {{ formatedData?.billTo?.name }}
          </p>
          <p class="font-semibold capitalize text-md">
            {{ formatedData?.billTo?.address }}
          </p>
          <p class="font-semibold text-md">{{ formatedData?.billTo?.email }}</p>
        </div>

        }
      </div>

      <div [ngClass]="{ 'max-w-[390px]': true }">
        <p class="text-xl font-bold">From:</p>

        <div class="w-full">
          <p class="mt-3 font-semibold capitalize text-md">
            {{ formatedData?.billFrom?.name }}
          </p>
          <p class="font-semibold capitalize text-md">
            {{ formatedData?.billFrom?.address }}
          </p>
        </div>
      </div>

      <div class="flex justify-end">
        <img
          src="assets/images/receipt/qrcode.png"
          class="w-[120px] overflow-hidden object-fill"
          appImg
        />
      </div>
    </div>

    <div [ngClass]="{ 'mt-14 flex flex-col': true }">
      <div [ngClass]="{ 'mt-6 hidden': true, '!block': isLoading }">
        <ng-container *ngTemplateOutlet="loadingTable"></ng-container>
      </div>
      <div
        [ngClass]="{
          'border border-[#0D3B66] flex-1 w-full min-h-[400px] relative': true,
          '!block': !isLoading,
          'invoice-table-container': !!isInvoice
        }"
      >
        <table class="mb-10">
          <thead>
            <tr>
              <th>Item</th>
              <th>Description</th>
              <th>Qty</th>
              <th>Rate (NGN)</th>
              <th>Amount (NGN)</th>
            </tr>
          </thead>
          <tbody>
            @for(item of formatedData.items; track $index) {
            <tr>
              <td>{{ $index + 1 + "." }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.rate }}</td>
              <td>{{ item.amount }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-end">
        <span class="px-10 py-3 bg-[#0D3B66] text-white">
          <p class="font-bold tracking-wide text-md">
            Total: {{ formatedData.totalAmount }}
          </p>
        </span>
      </div>
    </div>

    <img
      src="assets/images/receipt/badge.jpg"
      class="w-[150px] overflow-hidden object-fill"
      appImg
    />

    <p class="py-4 text-lg font-semibold tracking-wide capitalize">
      <span [ngClass]="{ 'uppercase font-extrabold tracking-tighter': true }"
        >Amount in Words:</span
      >
      {{ formatedData.totalAmountInWords }}
      Naira Only
    </p>

    <div class="flex items-center justify-between mt-7">
      <div class="flex flex-col space-y-5">
        <div class="flex space-x-1" *ngIf="!isInvoice">
          <div class="flex flex-col items-center space-y-1">
            <input type="checkbox" />
            <img
              src="assets/images/bank-branch.png"
              class="h-[14px] overflow-hidden object-fill"
              appImg
            />
          </div>

          <div class="flex flex-col items-center space-y-1">
            <input type="checkbox" />
            <img
              src="assets/images/interswitch.png"
              class="h-[14px] overflow-hidden object-fill"
              appImg
            />
          </div>

          <div class="flex flex-col items-center space-y-1">
            <input type="checkbox" />
            <img
              src="assets/images/remita.png"
              class="h-[14px] overflow-hidden object-fill"
              appImg
            />
          </div>
        </div>

        <div [ngClass]="{ 'flex flex-col': true }">
          <span>{{ "www.paykaduna.com" }}</span>
          <span>{{ "info@paykaduna.com" }}</span>
        </div>
      </div>

      <div *ngIf="!isInvoice">
        <span class="py-1 border-b-2 border-gray-500 mt-[-20px]">
          <img
            src="assets/signature_specimen/kadir_chairman_signature.png"
            class="w-[150px] overflow-hidden object-fill"
            appImg
          />
        </span>

        <p class="mt-3 font-bold text-md">Mr Jery Adams</p>
        <p class="text-sm font-bold">Executive Chairman KADIRS</p>
      </div>
    </div>
  </div>

  <img
    src="assets/images/receipt/footer.png"
    class="object-fill overflow-hidden"
    appImg
  />
</div>
<div [ngClass]="{ 'flex justify-center items-center py-4 pb-16': true }">
  <button
    (click)="downloadPDF()"
    type="button"
    class="px-5 py-3 mt-6 text-white rounded-lg min-w-32 bg-tw-primary"
  >
    Download PDF
  </button>
</div>

<ng-template #skeleton>
  @for (item of [1]; track $index) {

  <div [ngClass]="{ 'flex gap-4 flex-1': true }">
    <div [ngClass]="{ 'flex-1': true }">
      <ngx-skeleton-loader
        count="3"
        animation="progress"
        [theme]="{
          'height.px': 24,
          'background-color': '#E1E1E1',
          border: '1px solid white',
          'border-radius': '5px'
        }"
      />
    </div>
  </div>

  }
</ng-template>

<ng-template #loadingTable>
  <nz-table #loading_table [nzData]="dataLoading" [nzHideOnSinglePage]="true">
    <thead>
      <tr>
        @for (column of displayedColumns; track $index) {
        <th>{{ column }}</th>

        }
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of loading_table.data">
        @for (column of displayedColumns; track $index) {
        <td [ngClass]="{ '!py-2': true }">
          <ngx-skeleton-loader
            count="1"
            animation="progress"
            [theme]="{
              'height.px': 24,
              'background-color': '#E1E1E1',
              border: '1px solid white',
              'border-radius': '3px'
            }"
          />
        </td>

        }
      </tr>
    </tbody>
  </nz-table>
</ng-template>
